const YJ_USER_CODE = 'yujing_user_code'
// const TOP_STATUS = 'yujing_top_status'
const Exam_TYPE = 'yujing_exam_type'
const UTILS_TYPE = 'yujing_utils_type'
const USER_UOD = 'yujing_user_uod'
const USER_UUD = 'yujing_user_uunid'
const YJ_PAGE_ANSWER = 'yujing_answer' //计时答案
const YJ_PAGE_ANSWER_AI = 'yujing_ai_answer'//ai答案
const YJ_PAGE_Major = 'yujing_stu_major'//ai答案
const YJ_USER_EXETYPE = 'yujing_exercise_type'//知识答案类型
const YJ_USER_RECENTLY = 'yujing_recently'//刷新
const STU_CAPTER = 'yujing_capter'//划重点大标题记录
const STU_CAPTER_SMALL = 'yujing_capter_small'//划重点小标题记录
const YJ_USER_CODE_REFRESH_TOKEN = 'yujing_user_tokenRefresh'//刷新token

//uod
export const cunuod = (uod) => {
  localStorage.setItem(USER_UOD, JSON.stringify(uod))
}
// getuod
export const getuod = () => {
  const result = localStorage.getItem(USER_UOD)
  if (result !== 'undefined') {
    return result ? JSON.parse(result) : ''
  }
  else {
    return ''
  }
}
// uunid
export const cunuud = (uud) => {
  localStorage.setItem(USER_UUD, JSON.stringify(uud))
}
// getuunid
export const getuud = () => {
  const result = localStorage.getItem(USER_UUD)
  if (result !== 'undefined') {
    return result ? JSON.parse(result) : ''
  }
  else {
    return ''
  }
}


// 获取用户code 
export const getUserCode = () => {
  const result = localStorage.getItem(YJ_USER_CODE)
  return result ? JSON.parse(result) : ''
}
// 设置用户code
export const setUserCode = (code) => {
  localStorage.setItem(YJ_USER_CODE, JSON.stringify(code))
}
// 获取刷新token
export const getRefToken = () => {
  const result = localStorage.getItem(YJ_USER_CODE_REFRESH_TOKEN)
  return result ? JSON.parse(result) : ''
}
// 设置刷新token
export const setRefToken = (code) => {
  localStorage.setItem(YJ_USER_CODE_REFRESH_TOKEN, JSON.stringify(code))
}
// 获取做题状态
export const getExerciseType = () => {
  const result = localStorage.getItem(YJ_USER_EXETYPE)
  return result ? JSON.parse(result) : false
}
// 设置做题状态
export const setExerciseType = (type) => {
  localStorage.setItem(YJ_USER_EXETYPE, JSON.stringify(type))
}
// 获取最近状态划重点
export const getRecently = () => {
  const result = localStorage.getItem(YJ_USER_RECENTLY)
  return result !== 'undefined' ? JSON.parse(result) : ''
}
//设置最近状态划重点
export const setRecently = (list) => {
  localStorage.setItem(YJ_USER_RECENTLY, JSON.stringify(list))
}

// 获取答案计时考场
export const getAnswer = () => {
  const result = localStorage.getItem(YJ_PAGE_ANSWER)
  return result ? JSON.parse(result) : ''
}
// 设置答案计时考场
export const setAnswer = (data) => {
  localStorage.setItem(YJ_PAGE_ANSWER, JSON.stringify(data))

}
// 获取答案计时考场
export const getAiAnswer = () => {
  const result = localStorage.getItem(YJ_PAGE_ANSWER_AI)
  return result ? JSON.parse(result) : ''
}
// 设置答案计时考场
export const setAiAnswer = (data) => {
  localStorage.setItem(YJ_PAGE_ANSWER_AI, JSON.stringify(data))
}
// 存储大学生专业
export const getStuMajor = () => {
  const result = localStorage.getItem(YJ_PAGE_Major)
  return result ? JSON.parse(result) : ''
}
// 设置大学生专业
export const setStuMajor = (data) => {
  localStorage.setItem(YJ_PAGE_Major, JSON.stringify(data))
}
// 获取顶部index 
// export const getIndex = () => {
//   const result = localStorage.getItem(TOP_STATUS)
//   return result ? JSON.parse(result) : ''

// }
// // 设置顶部index
// export const setIndex = (index) => {
//   localStorage.setItem(TOP_STATUS, JSON.stringify(index))

// }
// 资格考试类型
export const getExamType = () => {
  const result = localStorage.getItem(Exam_TYPE)
  return result ? JSON.parse(result) : '1'
}
// 资格考试类型
export const setExamType = (index) => {
  localStorage.setItem(Exam_TYPE, JSON.stringify(index))
}
//划重点记录章节
export const getCapter = () => {
  const result = sessionStorage.getItem(STU_CAPTER)
  return result ? JSON.parse(result) : 0
}
//划重点记录章节
export const setCapter = (index) => {
  const a = +index
  sessionStorage.setItem(STU_CAPTER, JSON.stringify(a))
}

//划重点记录小章节
export const getCapterSmall = () => {
  const result = sessionStorage.getItem(STU_CAPTER_SMALL)
  return result ? JSON.parse(result) : 0
}
//划重点记录小章节
export const setCapterSmall = (id) => {
  sessionStorage.setItem(STU_CAPTER_SMALL, JSON.stringify(id))
}

//考试单元类型
export const getUtilsType = () => {
  const result = localStorage.getItem(UTILS_TYPE)
  // 检查result是否是一个非空字符串
  if (result) {
    try {
      // 尝试将result解析为JSON对象
      return JSON.parse(result);
    } catch (error) {
      // 如果解析过程中发生错误，打印错误并返回一个默认值
      // 这里可以根据实际需求返回一个更合适的默认值，以下返回的是一个空对象
      return '';
    }
  } else {
    // 如果result为空或未定义，返回一个默认值
    // 这里可以根据实际需求返回一个更合适的默认值，以下返回的是一个空对象
    return '';
  }

}
//考试单元类型
export const setUtilsType = (index) => {
  localStorage.setItem(UTILS_TYPE, JSON.stringify(index))

}