export default {
    namespaced: true,
    state() {
      return {
        timerDate:{}
  
  
      }
    },
    mutations: {
      updateList(state, data) {
        state.timerDate = data
      }
    },
  
    // actions: {
    //   async getList(context) {
    //     const res = await axios.get('http://localhost:3000')
    //     console.log(res)
    //   }
    // },
    getters: {}
  }