import Vue from 'vue'
import Vuex from 'vuex'
import timer from './modules/timer'
import history from './modules/history'
import everyday from './modules/everyday'
import user from './modules/user'
import echarts from './modules/echarts'

Vue.use(Vuex)

export default new Vuex.Store({
  // 严格模式
  strict: true,
  modules: {
    timer,
    history,
    everyday,
    user,
    echarts
  }
})
