import { getCountInfo } from '../../api/user'
export default {
  namespaced: true,
  state() {
    return {
      countList: [],
      option: {
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     type: 'shadow'
        //   }
        // },
        // grid: {
        //   left: '5%',
        //   right: '5%',
        //   bottom: '3%',
        //   containLabel: true
        // },
        xAxis: [
          {
            type: 'category',
            data: [],
            // axisTick: {
            //   alignWithLabel: true
            // }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: 'Direct',
            type: 'bar',
            barWidth: '60%',
            data: [],
            itemStyle: {
              color: '#2656FD',
              barBorderRadius: [5, 5, 0, 0]
              // 设置第一个柱状图系列的固定颜色
            }

          }
        ]
      }

    }
  },
  mutations: {
    updateList(state, data) {
      state.option.xAxis[0].data = data.xdata
      state.option.series[0].data = data.data
      state.countList = data.countList
      // state.historyDate = data
    },

  },

  actions: {
    async getEchartsList(context) {
      await getCountInfo().then(res => {
        if (res.code == 200) {
          const countList = res.data
          const xdata = res.data.accuracy ? res.data.accuracy.map(item => item.date_range.substring(6, 10)) : ''
          const data = res.data.accuracy ? res.data.accuracy.map(item => item.average_score) : ''
          context.commit('updateList', { xdata, data, countList })
        }
      })
    }
  },
  getters: {}
}