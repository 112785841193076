import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import CryptoJS from 'crypto-js';
// import axios from "axios";
// import VConsole from 'vconsole'
// var vConsole = new VConsole();
// Vue.use(vConsole)
import { ImagePreview, PasswordInput, NumberKeyboard, Picker, Field, Button, Switch, Collapse, CollapseItem, Checkbox, CheckboxGroup, Search, Icon, Cell, CellGroup, Popup, RadioGroup, Radio, Uploader, Toast, Swipe, SwipeItem, Col, Row, CountDown, DatetimePicker, List, Lazyload, Loading, Sticky, Circle, Progress, PullRefresh, Rate } from 'vant';
// ImagePreview //查看图片组件
// import VueWechatTitle from 'vue-wechat-title'
// Vue.use(VueWechatTitle)
Vue.use(Field).use(Button).use(Checkbox).use(CheckboxGroup).use(Search).use(Icon).use(Cell).use(CellGroup).use(Popup).use(Radio).use(RadioGroup).use(Uploader).use(Toast).use(Swipe).use(SwipeItem).use(Col).use(Row).use(CountDown).use(DatetimePicker).use(List).use(Lazyload).use(Loading).use(Sticky).use(Circle).use(Progress).use(PullRefresh).use(Switch).use(Rate).use(PasswordInput).use(NumberKeyboard).use(Collapse).use(CollapseItem).use(Picker);

const key = CryptoJS.enc.Utf8.parse('yujing1234567890');//16位
const iv = CryptoJS.enc.Utf8.parse('yujing123');
// 加密
Vue.prototype.$encrypt = function (data) {
  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
}
//解密
Vue.prototype.$decrypt = function (encryptedData) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData;
}
// 查看图片
Vue.prototype.$seeImage = function (img) {
  ImagePreview([
    img
  ]);
}
// 数组转换对象
Vue.prototype.$changeObject = function (list) {
  const listArray = new Array//一级数组
  const data = list//接口总数组
  for (let index = 0; index < data.length; index++) {
    const obj = new Object
    const dataX = data[index]//二级对象
    if (dataX) {
      obj.id = dataX.id
      obj.answer = dataX.answer
      obj.favorites = dataX.favorites
      obj.count = dataX.count
      obj.name = dataX.name
      obj.paperId = dataX.paperId
      obj.favoritesId = dataX.favoritesId
      obj.questionName = dataX.questionHtml
      obj.questionType = dataX.questionType
      obj.questionTypes = dataX.questionTypes
      obj.source = dataX.source
      obj.type = dataX.type
      obj.select = dataX.questionType
      obj.title = dataX.questionName
      obj.userAnswer = dataX.userAnswer ? dataX.userAnswer : ''
      obj.userAnswerText = ''
      obj.parse_image = dataX.parse_image
      obj.parse = dataX.parse || ''
      // obj.libraryResolve = dataX.parse || ''
      const optionText = {
        'A': dataX.optionAText,
        'B': dataX.optionBText,
        'C': dataX.optionCText,
        'D': dataX.optionDText,
        'E': dataX.optionEText,
        // ... 继续添加其他选项和文本
      }
      const optionsList = new Array
      const options = ['A', 'B', 'C', 'D', 'E']
      for (let o = 0; o < options.length; o++) {
        const opt = new Object
        opt.answerCode = options[o]
        opt.answerText = optionText[options[o]]
        optionsList.push(opt)
      }
      obj.optionsList = optionsList
      listArray.push(obj)
    }
    else {
      //this.$toast('数据出错了~')
    }
  }
  return listArray
}
// 获取用户code 
// Vue.prototype.$setAnswer = function (data) {
//   localStorage.setItem('yujingAnswer', JSON.stringify(data))
// }
// 设置用户code
// Vue.prototype.$getAnswer = JSON.parse(localStorage.getItem('yujingAnswer')) || undefined;
// 数组格式转换
Vue.prototype.$changeList = function (list) {
  const obj = new Object
  const dataX = list//二级对象
  if (dataX) {
    obj.answer = dataX.answer
    obj.id = dataX.id
    obj.isImage = dataX.isImage || 1
    obj.favorites = dataX.favorites
    obj.select = dataX.questionType
    obj.type = dataX.type
    obj.title = dataX.questionName
    obj.userAnswer = dataX.userAnswer || ''
    obj.parse_image = dataX.parse_image
    obj.libraryResolve = dataX.parse || ''
    obj.parse = dataX.parse || ''
    const optionText = {
      'A': dataX.optionAText,
      'B': dataX.optionBText,
      'C': dataX.optionCText,
      'D': dataX.optionDText,
      'E': dataX.optionEText,
      // ... 继续添加其他选项和文本
    }
    const optionsList = new Array
    const options = ['A', 'B', 'C', 'D', 'E']
    for (let o = 0; o < options.length; o++) {
      const opt = new Object
      opt.answerCode = options[o]
      opt.answerText = optionText[options[o]]
      optionsList.push(opt)
    }
    obj.optionsList = optionsList
  }
  else {
    //this.$toast('数据出错了~')
  }
  return obj
}
//图片压缩
// Vue.prototype.$compressImg = function compressImageByUrl(imageUrl, quality, callback) {
//   const img = new Image();
//   img.crossOrigin = 'Anonymous'; // 解决跨域问题，如果图片来自不同源
//   img.onload = function () {
//     const canvas = document.createElement('canvas');
//     const ctx = canvas.getContext('2d');
//     canvas.width = img.width;
//     canvas.height = img.height;
//     ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
//     const newImageData = canvas.toDataURL('image/jpeg', quality);
//     callback(newImageData);
//   };
//   img.onerror = function () {
//     console.error('There was an error loading the image.');
//   };
//   img.src = imageUrl;
// }
// 防抖函数
// function debounce(func, wait, immediate) {
//   let timeout; // 定义一个计时器变量，用于延迟执行函数
//   return function (...args) { // 返回一个包装后的函数
//     const context = this; // 保存函数执行上下文对象
//     const later = function () { // 定义延迟执行的函数
//       timeout = null; // 清空计时器变量
//       if (!immediate) func.apply(context, args); // 若非立即执行，则调用待防抖函数
//     };
//     const callNow = immediate && !timeout; // 是否立即调用函数的条件
//     clearTimeout(timeout); // 清空计时器
//     timeout = setTimeout(later, wait); // 创建新的计时器，延迟执行函数
//     if (callNow) func.apply(context, args); // 如果满足立即调用条件，则立即执行函数
//   };
// };

// // 在Vue实例化之前，添加防抖函数到Vue原型
// Vue.prototype.$debounce = debounce;

// 置顶
Vue.prototype.$scrollToTop = function (distance) {
  const X = distance || 0
  // 使用window.scrollTo方法立即滚动到页面顶部
  window.scrollTo({
    top: X,
    behavior: 'auto' // 'auto'表示立即滚动，也可以是'smooth'表示平滑滚动
  });
};
// 定义全局方法
Vue.prototype.$http = 'http://h5.medicine.yujingt.com';
// Vue.prototype.$http = 'http://test.h5.medicine.yujingm.cn';
Vue.prototype.$keepIndex = '1';//页面回退显示控制
// 获取wxcode回调openid
Vue.prototype.$getOpenid = function (p) {
  var local = encodeURIComponent(Vue.prototype.$http + `/getOpenid?path=${p}`);
  // 当前H5页面的url
  var appid = 'wx235c5cf1c9d6fb7b';
  var wxUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
    appid + '&redirect_uri=' +
    local + '&response_type=code&scope=snsapi_base#wechat_redirect'
  window.location.href = wxUrl;
};

// 题目类型
Vue.prototype.$examInfoType = function (type) {
  switch (+type) {
    case 1:
      return '单选';
    case 2:
      return '多选';
    case 3:
      return '简答';
    case 4:
      return '填空';
    case 5:
      return '名词解释';
    case 6:
      return '论述';
    default:
      return '';
  }
};

Toast.setDefaultOptions({ duration: 1000 });
// 在Vue实例创建之前添加百度统计脚本
function addBaiduScript() {
  var _hmt = window._hmt = window._hmt || [];
  (function () {
    var hm = document.createElement("script");
    if (Vue.prototype.$http == 'http://h5.medicine.yujingt.com') {
      hm.src = "https://hm.baidu.com/hm.js?b72edeb3d0b3e20f05b2bc77f97ea278";
    }
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();
}
addBaiduScript();
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

