import { getUserMemberInfo } from '../../api/user'

export default {
  namespaced: true,
  state() {
    return {
      userInfo: {
        ship1: '',
        ship2: '',
        ship3: '',
      },
      loading: true,
    }
  },
  mutations: {
    member(state, data) {
      try {
        if (data) {
          state.userInfo.ship1 = data.ship1
          state.userInfo.ship2 = data.ship2
          state.userInfo.ship3 = data.ship3
        }
      }
      catch {

      }
      // console.log(data, 'data');
    },
    // 控制记载东动画
    updateLoading(state, data) {
      state.loading = data
    }
  },
  actions: {
    // 获取用户会员信息
    async userMemberInfo(context) {
      await getUserMemberInfo().then(res => {
        context.commit('member', res.data)
      })
    },
  },
  getters: {}
}