export default {
  namespaced: true,
  state() {
    return {
      everydayDate: [],
      score: '',
      everydayDateDetail: {}


    }
  },
  mutations: {
    updateList(state, data) {
      state.everydayDate = data
    },
    updateScore(state, data) {
      state.score = data
    },
    updateListDetail(state, data) {
      state.everydayDateDetail = data
    }
  },

  // actions: {
  //   async getList(context) {
  //     const res = await axios.get('http://localhost:3000')
  //     console.log(res)
  //   }
  // },
  getters: {}
}